import gql from 'graphql-tag'

export default (productId, language) => gql`{
  commerceProductById (
    id: "${productId}",
    language: ${language},
  ) {

      entityId
      entityBundle
      title
    
    	... on CommerceProductDefault {
				body {
        	processed
      	}

        fieldCategory {
          entity {
            name
          }
        }

        fieldMenuItem {
          entity {
            entityId
            entityLabel
          }
        }

        # fieldLinkMoodle {
        #   uri
        #   url {
        #     path
        #     routed
        #   }
        #   title
        # }

        fieldRelatedProducts {
          entity {
            entityLabel
          }
        }

        variations {
          entity {
            variationId
            entityBundle
            sku
            price {
              number
              currencyCode
              formatted
            }
            resolvedPrice {
              number
              currencyCode
              formatted
            }
            calculatedPrice {
              number
              currencyCode
              formatted
            }
            # entityRendered # this contains what would Drupal render, the benefit of this field it behaves the same way as Twig frontend!
            
            ... on CommerceProductVariationDefault {

              fieldImages {
                entity {
                  entityUuid
                }

                derivative(style: MAX650X650) {
                  url
                  width
                  height
                }
              }

              attributeSize {
                entity {
                  ... on CommerceProductAttributeValueSize {
                    entityId
                    entityLabel
                    weight
                  }
                }
              }

              attributeColor {
                entity {
                  ... on CommerceProductAttributeValueColor {
                    entityId
                    entityLabel
                    weight
                  }
                }
              }

              commerceStockAlwaysInStock
              fieldStockLevel {
                availableStock
              }

            }

          }
        }
        
    	}

  }
}`
