/**
 * @todo: Alwayts return cheapest vriation for a given attributes combination
 * @see: https://reactjs.org/docs/forms.html#the-select-tag
 */

import React, { PureComponent }  from 'react'
import PropTypes from 'prop-types';
import { cartAdd } from '../../../actions'
import { connect } from 'react-redux'
import _ from 'lodash'

// s4n
import { injectIntl } from "gatsby-plugin-intl"
import ProductPrice from './../../../../Product/price'

import { DisplayFormikState } from './../../../../../../forms/formikHelper';



class Variations extends PureComponent {
  static propTypes = {
    variations: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  constructor (props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    const { variations } = props;

    const { intl } = props;
    const t = intl.formatMessage;
    this.t = t; // this.t = this.t.bind(this);
    this.locale = intl.locale;

// console.log(variations)
    this.variations = this.filterVariations(variations);
// console.log(this.variations)
    const {entity: defaultVariation} = this.variations[0];
// console.log(defaultVariation)

    this.state = {
      activeVariation: defaultVariation,
    }
  }

  filterVariations(variations) {
    let filteredVariations = [];
    variations.forEach(({ entity: variation }, key) => {
      let variationEnabled = false;
      if (
        variation.commerceStockAlwaysInStock 
        || (!variation.commerceStockAlwaysInStock && variation.fieldStockLevel.availableStock > 0)
      ) {
        variationEnabled = true;
      }

      if (variationEnabled) {
        let aVariationLabels = [];
        _.each(variation, (value, key) => {
            // Variation attribute key starts with `attribute` - this is how Drupal Commerce does this
          if (key.startsWith(`attribute`) && null !== value) {
            const attributeObject = value; // ex. {entity: {entityId: "14", entityLabel: "Red", weight: 1, __typename: "CommerceProductAttributeValueColor"}, __typename: "FieldCommerceProductVariationDefaultAttributeColor" ...}
            const attributeName = key; // ex. `attributeColor`, `attributeSize`
    
            aVariationLabels.push(attributeObject.entity.entityLabel);
          }
        })

        variation.label = aVariationLabels.join(` / `);
        // variation.label += ` (stock level: ${variation.fieldStockLevel.availableStock})`

        filteredVariations.push({entity: variation});
      }
    });

    return filteredVariations;

    return _.each(variations, ({ entity: variation }, key) => {

      let variationEnabled = false;
      if (
        variation.commerceStockAlwaysInStock 
        || (!variation.commerceStockAlwaysInStock && variation.fieldStockLevel.availableStock > 0)
      ) {
        variationEnabled = true;
      }

      if (!variationEnabled) {
        return false;
      }

      let aVariationLabels = [];
      _.each(variation, (value, key) => {
          // Variation attribute key starts with `attribute` - this is how Drupal Commerce does this
        if (key.startsWith(`attribute`) && null !== value) {
          const attributeObject = value; // ex. {entity: {entityId: "14", entityLabel: "Red", weight: 1, __typename: "CommerceProductAttributeValueColor"}, __typename: "FieldCommerceProductVariationDefaultAttributeColor" ...}
          const attributeName = key; // ex. `attributeColor`, `attributeSize`
  
          aVariationLabels.push(attributeObject.entity.entityLabel);
        }
      })

      variation.label = aVariationLabels.join(` / `);
      // variation.label += ` (stock level: ${variation.fieldStockLevel.availableStock})`

      return variation;
      
      // else {
      //   delete variations[key];
      // }

    })

  }

  getResolvedVariation(variationId) {
    return this.variations.filter(({ entity: variation }, key) => variation.variationId === variationId).shift().entity;
  }

  // onChange={event => this.setState({ [event.target.name]: event.target.value })}
  onChange({ target: { name, value } }) {
    const variationId = Number(value);

    this.setState({
      activeVariation: this.getResolvedVariation(variationId)
    })
  }

  onAddToCart() {
    const { dispatch } = this.props;
    dispatch(cartAdd(this.state.activeVariation))
  }

  render() {
    const t = this.t;

    // validation if attribute value for variation is not set in admin
    if (_.isEmpty(this.variations)) {
        return null;
    }

    if (!this.state.activeVariation) {
        return null;
    }

    return (
      <React.Fragment>
        <ProductPrice selectedVariation={this.state.activeVariation} printLabel={true} />

        <p>{`Stock level: ${this.state.activeVariation.fieldStockLevel.availableStock}`}</p>

        <div className={`field--name-variations`}>
          <div className={`attribute-widgets form-group`}>
            <div className={`field--item form-type-select`}>
                
                {/* <DisplayFormikState {...this.state} activeVariation={null} /> */}
                {/* <DisplayFormikState activeVariation={this.state.activeVariation} /> */}
                {/* <DisplayFormikState {...this.variations} /> */}
                {/* <DisplayFormikState {...this.state.activeVariation} /> */}

                <div className="panel-title form-required">
                  { t({ id: `soft4net_shop_commerce_product_page_variations` }) }
                </div>
                <div className="select-wrapper">
                  <select name={`variationList`} className={`custom-select`} onChange={this.onChange} value={this.state.activeVariation.variationId}>
                    {this.variations.map(({ entity: variation }, key) => {
                      return (
                        <option value={variation.variationId} key={key}>{variation.label}</option>
                      )
                    })}
                  </select>
                </div>

            </div>

          </div>
          <div className={`field--item add-to-cart`}>
            <button className="button button--primary js-form-submit form-submit btn-success btn" type="button" onClick={this.onAddToCart.bind(this)}>
              <i className="fas fa-cart-plus mr-2" aria-hidden="true"></i> { t({ id: `soft4net_shop_commerce_product_page_add_to_cart` }) }
            </button>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

// export default connect()(Variations);
const VariationsWrapped = injectIntl(Variations);
export default connect()(VariationsWrapped);
